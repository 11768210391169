import { adminurl, weburl, imgapi } from '@/utils/config'
import tools from "@/utils/public.js";
import store from "@/store";
const { toGet, toPost } = tools;
// sotre值
const shopId = store.getters.getShopId
// console.log('vuex', store, shopId)


// 导出
export default {
    /**
     * 
     * 专区
     */
    // 展示专区
    async Types() {
        return await toGet(weburl + "shop/good/label/show/", shopId)
    },
    // 新增专区
    async addTypes(data) {
        data = {
            ...data,
            shop_id: shopId
        }
        return await toPost(weburl + "shop/good/label/add", data)
    },
    // 修改专区
    async editTypes(data) {
        return await toPost(weburl + "shop/good/label/update", data)
    },
    // 修改专区状态
    async typesStatus(data) {
        return await toPost(weburl + "shop/good/label/update_label_status", data)
    },
    // 删除专区
    async deleteTypes(id) {
        return await toGet(weburl + "shop/good/label/delete/", id)
    },

    /**
     * 
     * 类别
     */
    // 展示类别
    async Class() {
        return await toGet(weburl + "shop/good/category/show/", shopId)
    },
    // 新增类别
    async addClass(data) {
        data = {
            ...data,
            shop_id: shopId
        }
        return await toPost(weburl + "shop/good/category/add", data)
    },
    // 修改类别
    async editClass(data) {
        return await toPost(weburl + "shop/good/category/update", data)
    },
    // 删除类别
    async deleteClass(id) {
        return await toGet(weburl + "shop/good/category/delete/", id)
    },

    /**
     * 
     * 商品规格
     */
    // 展示规格
    async Specs() {
        return await toGet(weburl + "shop/good/spec/show/", shopId)
    },
    // 新增规格
    async addSpecs(data) {
        data = {
            ...data,
            shop_id: shopId
        }
        return await toPost(weburl + "shop/good/spec/add", data)
    },
    // 修改规格
    async editSpecs(data) {
        return await toPost(weburl + "shop/good/spec/update", data)
    },
    // 删除规格
    async deleteSpecs(data) {
        return await toPost(weburl + "shop/good/spec/delete", data)
    },

    /**
     * 
     * 商品
     */
    async goodsList(data) {
        data = {
            ...data,
            shop_id: shopId
        }
        return await toPost(weburl + "shop/good/show", data)
    },
    // 上下架商品
    async goodsStatus(data) {
        return await toPost(weburl + "shop/good/modify_good_status", data)
    },
    // 删除商品
    async deleteGoods(id) {
        return await toGet(weburl + "shop/good/delete/", id)
    },
    // 商品规格列表
    async goodsBase(id) {
        return await toGet(weburl + "shop/good/good_base/", id)
    },
    // 上下架规格
    async goodsBaseStatus(data) {
        return await toPost(weburl + "shop/good/modify_good_base_status", data)
    },
    // 修改商品信息
    async updateGoods(data) {
        return await toPost(weburl + "shop/good/update", data)
    },
    // 修改商品规格信息
    async updateGoodsBase(data) {
        return await toPost(weburl + "shop/good/update_base", data)
    },
    // 新增商品规格信息
    async addGoodsBase(data) {
        data = {
            ...data,
            shop_id: shopId
        }
        return await toPost(weburl + "shop/good/add_base", data)
    },
    // 商品评论
    async goodsComments(id) {
        return await toGet(weburl + "good/product_comments/", id)
    },
    // 新增商品规格信息
    async addGoods(data) {
        data = {
            ...data,
            shop_id: shopId
        }
        return await toPost(weburl + "shop/good/add", data)
    },

    // 展示后台需要的商品
    async getGoodsToConfig() {
        return await toGet(adminurl + "system/get_goods_to_config/", shopId)
    },


    // 获取运费模板
    async transport(data) {
        return await toPost(adminurl + "order/transport/show/", shopId)
    },



    // 团购商品
    async teamGoodsList(data) {
        data = {
            ...data,
            shop_id: shopId
        }
        return await toPost(weburl + "shop/good/teamwork/show", data)
    },
    // 添加团购商品
    async addTeamGoods(data) {
        data = {
            ...data,
            shop_id: shopId
        }
        return await toPost(weburl + "shop/good/teamwork/add", data)
    },
    // 修改团购商品信息
    async updateTeamGoods(data) {
        return await toPost(weburl + "shop/good/teamwork/update", data)
    },
    // 修改团购商品信息
    async deleteTeamGoods(id) {
        return await toPost(weburl + "shop/good/teamwork/delete", id)
    },
}