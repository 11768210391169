// 站点信息
export const webinfo = {
    name: '朋来乐家政服务平台',
    spimpleName: '朋来乐',
    company: '朋来乐家政服务平台'
}
//
// // 正式服
// export const imgapi = "https://xinmaijia.oss-cn-chengdu.aliyuncs.com/"; // OSS地址
// export const adminurl = "https://sys.xmjservice.com/api/admin/";
// export const weburl = "https://sys.xmjservice.com/api/";
// export const uploadApi = "https://sys.xmjservice.com/api/upload_img";
// export const delOssApi = "https://sys.xmjservice.com/api/del_img/";

// // 本地测试服
// export const imgapi = "https://penglaile.oss-cn-chengdu.aliyuncs.com/"; // OSS地址
// export const adminurl = "http://127.0.0.1:8000/api/admin/";
// export const weburl = "http://127.0.0.1:8000/api/";
// export const uploadApi = "http://127.0.0.1:8000/api/upload_img";
// export const delOssApi = "http://127.0.0.1:8000/api/del_img/";



// export const imgapi = "https://penglaile.oss-cn-chengdu.aliyuncs.com/"; // OSS地址
// export const adminurl = "http://back.penglaile.com/api/admin/";
// export const weburl = "http://back.penglaile.com/api/";
// export const uploadApi = "http://back.penglaile.com/api/upload_img";
// export const delOssApi = "http://back.penglaile.com/api/del_img/";


export const imgapi = "https://penglaile.oss-cn-chengdu.aliyuncs.com/"; // OSS地址
export const adminurl = "https://back.penglaile.com/api/admin/";
export const weburl = "https://back.penglaile.com/api/";
export const uploadApi = "https://back.penglaile.com/api/upload_img";
export const delOssApi = "https://back.penglaile.com/api/del_img/";

//todo 前后端连调出问题了。。。为什么只能显示一半 后端功能到底哪里出了问题。。。？


// https://47.108.202.224:



// // 测试服API地址
// export const imgapi = "https://xinmaijia.oss-cn-chengdu.aliyuncs.com/"; // OSS地址
// export const adminurl = "https://sys.poolaiwisdom.com/api/admin/";
// export const weburl = "https://sys.poolaiwisdom.com/api/";
// export const uploadApi = "https://sys.poolaiwisdom.com/api/upload_img";
// export const delOssApi = "https://sys.poolaiwisdom.com/api/del_img/";

// 腾讯地图Key
export const txMapKey = "FXZBZ-IQ3LV-PPJPL-5UQKH-L4GTS-M2BIO";